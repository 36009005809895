import { Component, OnInit } from '@angular/core';
import { FacebookPixelEventTrackerService } from '../services/facebook-pixel-event-tracker.service';
import { SeiteCallEventTrackingService } from '../services/seite-call-event-tracking.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(private tracking: FacebookPixelEventTrackerService, private trackingSeiteCall: SeiteCallEventTrackingService) { }

  ngOnInit(): void {
    // this.tracking.trackEvent('ViewContent');
    // this.trackingSeiteCall.trackEvent();
    if (window.location.href.endsWith('.com/y')) {
      window.location.href = "https://developerakademie.com?utm_source=youtube&utm_medium=video&utm_campaign=yt_video&utm_id=regular_video&utm_content=yt_description";
    } else if (window.location.href.endsWith('.com/ya')) {
      window.location.href = "https://developerakademie.com?utm_source=youtube&utm_medium=video&utm_campaign=yt_ad&utm_id=paid_video&utm_content=yt_ad_clicked";
    } else if (window.location.href.endsWith('.com/t')) {
      window.location.href = "https://developerakademie.com?utm_source=tiktok&utm_medium=profile&utm_campaign=tiktok&utm_id=tiktok_video&utm_content=tiktok_profile";
    } else {
      window.location.href = "https://developerakademie.com/";
    }
  }

}
