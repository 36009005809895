import { Section } from "custom-form-lib";

const startFullStackSection = {
    contentId: "Start",
    onInvalidMessage: "Starten",
    onValidMessage: "Starten",
    formGroupType: "BooleanInputControl",
    options: [`
      <div class="1500:ml-[30px] ml-3
      text-[14px] 600:text-[14px] 1200:text-[16px] 1500:text-[22px] dark:text-[#B6C4C9]">
        Bitte stimme kurz der
        <span >
          <a style="text-decoration-skip-ink: none" class="underline  dark:text-white text-daBlue_85" rel="noopener noreferrer" target="_blank"
            onclick="event.stopPropagation();"
            href="https://developerakademie.com/data-protection">Datenschutzerklärung</a>
        </span> zu.
      </div>`
    ], //title per option
    required: true,
    formControlNames: ["accept"], //one formControlName per option
    // title: `Starte deine Karriere als <span class="text-orange">DevSecOps</span>`
} as Section;

const introductionSection = {
    contentId: "Introduction",
    formGroupType: "TextInputControl",
    options: ["Dein Vorname", "Dein Nachname"],
    formControlNames: ["firstName", "lastName"],
    required: true,
    title: `
    <p class="text-[22px] font-bold font-figtree
      900:text-[28px]">
      <span class="text-orange">
        Schön, dass Du hier bist.
      </span> Bitte stelle dich kurz vor.
    </p>`
} as Section;

const ageSection = {
    formGroupType: "SingleChoiceControl",
    options: ["18-25", "26-35", "36-45", "46+"],
    formControlNames: ["age"],
    required: true,
    title: `Wie alt bist du?`
} as Section;

const professionSection = {
    formGroupType: "SingleChoiceControl",
    options: [
        "Schüler / Schülerin",
        "Student / Studentin",
        "Angestellter / Angestellte",
        "Selbständig",
        "Arbeitsuchend",
        "Sonstiges"
    ],
    formControlNames: ["profession"],
    required: true,
    title: `Dein aktueller Berufsstand`
} as Section;

const experienceSection = {
    contentId: "FullStackExperienceId",
    formGroupType: "SingleChoiceControl",
    formControlNames: ["experience"],
    options: [
        'Ich habe noch nie eine Zeile Programm-Code geschrieben.',
        'Ich habe schon den ein oder anderen kleinen Kurs gemacht, aber ich habe dann irgendwann aufgehört weiterzumachen.',
        'Ich kann bereits die ein oder andere Programmiersprache ganz gut, bin aber noch lange kein Programmierer.',
        'Ich kenne mich mit Objektorientierter Programmierung aus und habe bereits meine eigene Websiten, Apps und Programme geschrieben.'
    ],
    title: "Welche der folgen Aussagen beschreibt Deine aktuelle Programmier-Erfahrung am besten?",
    required: true
} as Section;

const desiresSection = {
    formGroupType: "MultipleChoiceControl",
    formControlNames: ["desires"],
    options: [
        'Webseiten', 'Apps', 'Spiele', 'Business Anwendungen', 'IT-Sicherheit'
    ],
    title: "Welche IT-Bereiche interessieren dich besonders?",
    required: true
} as Section;

const stacksSection = {
    contentId: "Stacks",
    formGroupType: "LastChoiceSelectsAllControl",
    formControlNames: ["stacks"],
    options: [
        'Frontend', 'Backend', 'Fullstack'
    ],
    title: "Für welche Bereiche interessierst du dich?",
    required: true
} as Section;

const willSection = {
    onInvalidMessage: "Bitte ausfüllen",
    formGroupType: "TextAreaInputControl",
    formControlNames: ["will"],
    options: [
        "Gib hier deine Antwort ein..."
    ],
    required: true,
    title: "Warum willst Du Dich online zum IT-Spezialisten weiterbilden?"
} as Section;

const emailSection = {
    contentId: "EmailAddress",
    onInvalidMessage: "Bitte ausfüllen",
    formGroupType: 'EmailInputControl',
    formControlNames: ['email'],
    options: [
        "Deine E-Mail Adresse"
    ],
    title: 'Deine E-Mail für die Terminbestätigung',
    required: true,
} as Section;

const phoneNumberSection = {
    formGroupType: "PhoneInputControl",
    formControlNames: ["phoneNumber"],
    options: ["012345678901"],
    preselectedValues: ["+49"],
    title: "Unter welcher Telefonnummer können wir Dich für das Beratungsgespräch erreichen?",
    required: true
} as Section;

const reachableSection = {
    formGroupType: "SingleChoiceControl",
    formControlNames: ["reachable"],
    options: [
        "Morgens, zwischen 8-12 Uhr",
        "Nachmittags, zwischen 13-17 Uhr",
        "Abends, zwischen 17-20 Uhr"
    ],
    title: "Wann bist Du in der Regel am besten (telefonisch) erreichbar?",
    onValidMessage: "Übermitteln",
    required: true
} as Section;

/**
 * @deprecated
 */
const currentSituationSection = {
    formGroupType: "SingleChoiceControl",
    formControlNames: ["situation"],
    options: [
        'Ich habe die finanzielle Möglichkeit, um in meine Weiterbildung zu investieren.',
        'Ich habe im Notfall Zugang zu finanziellen Ressourcen, um in meine Weiterbildung zu investieren.',
        'Ich habe nicht die finanziellen Möglichkeiten, um in meine berufliche Zukunft zu investieren und habe dies auch nicht vor.'
    ],
    title: "Beschreibe bitte Deine aktuelle Situation",
    required: true
} as Section;

export const fullstackSections = [
    startFullStackSection,
    introductionSection,
    ageSection,
    professionSection,
    experienceSection,
    desiresSection,
    stacksSection,
    willSection,
    emailSection,
    phoneNumberSection,
    reachableSection,
];
